import React from 'react'
import styled from 'styled-components'
import omit from 'lodash/omit'
import { space, color, size, display } from 'styled-system'
import { variant } from 'styled-system'

export const theme = {
  glyphs: {
    daily: { content: "'\\0046'" },
    facebook: { content: "'\\0041'" },
    hamburger: { content: "'\\0042'" },
    instagram: { content: "'\\0043'" },
    linkedin: { content: "'\\0044'" },
    meetings: { content: "'\\0045'" },
    twitter: { content: "'\\0047'" }
  }
}

const iconVariants = variant({ key: 'glyphs', prop: 'variant' })

const Component = styled.div`
  &::before {
    font-family: foodsby-icons;
    font-weight: normal;
    font-style: normal;
    ${iconVariants}
  }
`
Component.defaultProps = {
  theme
}
Component.propTypes = {
  ...space.propTypes,
  ...color.propTypes
}

export const Icon = ({ ...rest }) => (
  <Component
    {...omit(rest, [...Object.keys(space, color, size, display)])}
  ></Component>
)
export default Icon
