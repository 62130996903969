import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import conweb from '../js/themes/conweb'

/**
 * Documentation on the gatsby-theme-docz: https://github.com/doczjs/docz/tree/master/core/gatsby-theme-docz
 */
const Wrapper = ({ children }) => (
  <>
    <Helmet>
      <script
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBDH0Ysz12SLHmVyPo0nsisYMBY6kaUAcU&libraries=places&callback=loadGoogleMaps"
        type="text/javascript"
      />
    </Helmet>
    <ThemeProvider theme={conweb}>{children}</ThemeProvider>
  </>
)

Wrapper.propTypes = {
  children: PropTypes.node
}
export default Wrapper
